/* Home.css */

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: Adds a slight overlay */
    z-index: 9999; /* Ensures it is above other content */
  }
  